import { usePreviousAchievementsV3Progress } from '@thriveglobal/thrive-web-achievements'
import { useIsFeatureEnabled } from '@thriveglobal/thrive-web-core'
import isAfter from 'date-fns/isAfter'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import {
    DisplayTarget,
    NudgeMetadata,
    PlatformCategory,
    useGetPendingNudgesQuery
} from '../../graphql/generated/autogenerated'
import { GQLNullValue, ReactNullValue } from '../../utils/nulls'
import { useBrowserNotification } from '../useBrowserNotification/useBrowserNotification'
import {
    NewAchievementCountType,
    PseudoBrowserPermissionNotification
} from '../useGenerateNotification/pseudo/psuedoNotifications'
import useGenerateNotification, {
    Notification
} from '../useGenerateNotification/useGenerateNotification'
import useLocalStorage from '../useLocalStorage/useLocalStorage'
import useZonedDateTime from '../useZonedDateTime/useZonedDateTime'

const payload = {
    input: {
        isExpired: false,
        isSent: GQLNullValue,
        platformCategories: [PlatformCategory.Web],
        within: GQLNullValue
    }
}
const todaysNotificationsKey = 'todaysNotifications'

const isNotificationEqual = (
    notification1: Notification,
    notification2: Notification
) => {
    return (
        notification1.nudgeType === notification2.nudgeType &&
        notification1.sendDate === notification2.sendDate &&
        notification1.sendTime === notification2.sendTime
    )
}

const areNotificationArraysEqual = (
    array1: Notification[],
    array2: Notification[]
) => {
    if (array1.length !== array2.length) return false
    return array1.every((notification, index) =>
        isNotificationEqual(notification, array2[index])
    )
}

export type useGetPendingNudgesType = {
    notifications: Notification[]
    hasUnreadNotifications: boolean
    refetchNotifications: () => void
    setNotificationsRead: () => void
    setNotificationClicked: (notification: Notification) => void
}

export const useGetPendingNudges = (): useGetPendingNudgesType => {
    const { data, refetch, loading } = useGetPendingNudgesQuery({
        variables: payload,
        fetchPolicy: 'cache-and-network'
    })

    const newAchievementNotificationCounterEnabled = useIsFeatureEnabled(
        'NewAchievementNotificationCounter',
        ReactNullValue,
        true
    )

    const { newAchievementsCount } = usePreviousAchievementsV3Progress()

    const PseudoNewAchievementCountNotification = useMemo(() => {
        return {
            read: false,
            expired: false,
            clicked: false,
            expiresAt: ReactNullValue,
            nudgeMetadata: {
                newAchievementsCount
            } as NudgeMetadata,
            nudgeType: NewAchievementCountType,
            producedAt: ReactNullValue,
            sendDate: 'pseudo-new-achievement-send-date',
            sendTime: 'pseudo-new-achievement-send-time',
            sentAt: ReactNullValue,
            targetTimestamp: ReactNullValue,
            thriveUserId: ReactNullValue,
            whereToShow: [DisplayTarget.WebNotify]
        }
    }, [newAchievementsCount])

    const { generateNotification, logResourceServed } =
        useGenerateNotification()

    const {
        isPermissionGranted,
        showNotification,
        canUseBrowserNotifications
    } = useBrowserNotification()
    const browserNotificationsEnabled = useIsFeatureEnabled(
        'BrowserNotifications',
        ReactNullValue,
        true
    )

    const location = useLocation()
    const { getZonedDate } = useZonedDateTime()
    const { get: getStoredNotifications, set: setStoredNotifications } =
        useLocalStorage<Notification[]>(todaysNotificationsKey)
    const [notifications, setNotifications] = useState<Notification[]>([])

    useEffect(() => {
        // Every 5 minutes, refetch the data.
        const interval = setInterval(() => {
            refetch(payload)
        }, 300000)

        return () => clearInterval(interval)
    }, [refetch])

    const refetchNotifications = useCallback(
        () =>
            refetch({
                input: {
                    isSent: GQLNullValue,
                    platformCategories: [PlatformCategory.Web],
                    within: GQLNullValue
                }
            }),
        [refetch]
    )

    useEffect(() => {
        if (loading) return

        // Step 1: Expire old notifications
        const handleExpiredNotifications = (
            notifications: Notification[] = []
        ) => {
            const currentZonedDate = getZonedDate()
            notifications.forEach((notification) => {
                if (!notification.expired) {
                    notification.expired =
                        notification.expiresAt &&
                        isAfter(
                            currentZonedDate,
                            getZonedDate(new Date(notification.expiresAt))
                        )
                }
            })
        }

        const storedNotifications = getStoredNotifications() ?? []
        const pendingNudges = data?.centralizedNotifications
            ?.pendingNudges as any
        const results = (pendingNudges?.result as Notification[]) ?? []

        // Step 2: Handle pseudo notification for browser permissions
        const pseudoBrowserPermissionNotificationExists = results.some(
            (notification: Notification) =>
                notification.nudgeType ===
                PseudoBrowserPermissionNotification.nudgeType
        )
        if (
            browserNotificationsEnabled &&
            !isPermissionGranted &&
            !pseudoBrowserPermissionNotificationExists &&
            canUseBrowserNotifications
        ) {
            results.push(PseudoBrowserPermissionNotification)
        }

        // Step 2.1: Handle pseudo notification for new achievements count
        if (newAchievementNotificationCounterEnabled) {
            const pseudoNewAchievementNotificationExists = results.some(
                (notification: Notification) =>
                    notification.nudgeType ===
                    PseudoNewAchievementCountNotification.nudgeType
            )
            if (
                !pseudoNewAchievementNotificationExists &&
                newAchievementsCount > 0
            ) {
                results.push(PseudoNewAchievementCountNotification)
            }
        }

        // Step 3: Merge notifications (preserving existing notification states)
        // mergedNotifications will contain the merged list of storedNotifications and new notifications
        // newNotifications will contain only the new notifications (so we can use it further down without having to filter the mergedNotifications list)
        const { mergedNotifications, newNotifications } = results.reduce<{
            mergedNotifications: Notification[]
            newNotifications: Notification[]
        }>(
            (acc, notification: Notification) => {
                const existingNotification = storedNotifications.find(
                    (storedNotification: Notification) =>
                        isNotificationEqual(notification, storedNotification)
                )

                if (existingNotification) {
                    // Preserve the `read`, `expired` and `clicked` states
                    acc.mergedNotifications.push({
                        ...notification,
                        read: existingNotification.read,
                        expired: existingNotification.expired,
                        clicked: existingNotification.clicked
                    })
                } else {
                    notification.read = false
                    acc.mergedNotifications.push(notification)
                    acc.newNotifications.push(notification)
                }

                return acc
            },
            { mergedNotifications: [], newNotifications: [] }
        )

        // Step 4: Handle expiration for merged + stored notifications
        handleExpiredNotifications(storedNotifications)
        handleExpiredNotifications(mergedNotifications)

        // Step 5: Update notifications state only when necessary
        const newDataExists = data && newNotifications.length > 0

        // 5.1: No new notifications case
        if (storedNotifications.length > 0 && !newDataExists) {
            setNotifications((prevNotifications: Notification[]) => {
                if (
                    !areNotificationArraysEqual(
                        storedNotifications,
                        prevNotifications
                    )
                ) {
                    setStoredNotifications(storedNotifications)
                    return storedNotifications
                }
                return prevNotifications
            })
            return
        }

        // 5.2 New notifications or changes in the notification state
        if (data) {
            const attemptToGenerateBrowserNotification =
                isPermissionGranted && browserNotificationsEnabled
            if (newNotifications.length > 0) {
                newNotifications
                    .filter((notification) => !notification.expired)
                    .forEach((notification) => {
                        logResourceServed(notification)
                        if (attemptToGenerateBrowserNotification) {
                            const { browserNotification } =
                                generateNotification(notification)
                            if (
                                browserNotification &&
                                notification.whereToShow.includes(
                                    DisplayTarget.WebNotify
                                )
                            )
                                showNotification(
                                    browserNotification.title,
                                    browserNotification.url,
                                    browserNotification.tag,
                                    browserNotification.options
                                )
                        }
                    })
            }
            /**
             * TODO @jeff-pezzone: I'm not convinced the if conditional below is actually needed based on my testing.
             * I think we could probably collapse this under the if (newNotifications.length > 0)
             */
            if (
                !areNotificationArraysEqual(mergedNotifications, notifications)
            ) {
                setStoredNotifications(mergedNotifications)
                setNotifications(mergedNotifications)
            }
        }
    }, [
        data,
        loading,
        getZonedDate,
        notifications,
        showNotification,
        logResourceServed,
        isPermissionGranted,
        generateNotification,
        newAchievementsCount,
        getStoredNotifications,
        setStoredNotifications,
        canUseBrowserNotifications,
        browserNotificationsEnabled,
        PseudoNewAchievementCountNotification,
        newAchievementNotificationCounterEnabled
    ])

    const { hasUnreadNotifications, unreadNotificationCount } = useMemo(() => {
        const unreadNotifications = notifications?.filter(
            (notification) =>
                !notification.read &&
                !notification.expired &&
                !notification.clicked
        )
        return {
            hasUnreadNotifications: unreadNotifications?.length > 0,
            unreadNotificationCount: unreadNotifications?.length
        }
    }, [notifications])

    const handleTitleUpdate = useCallback(() => {
        const timeoutId = setTimeout(() => {
            const regex = /\(\d+\)\s/
            let updatedTitle = document.title

            if (unreadNotificationCount > 0) {
                if (regex.test(updatedTitle)) {
                    updatedTitle = updatedTitle.replace(
                        regex,
                        `(${unreadNotificationCount}) `
                    )
                } else {
                    updatedTitle = `(${unreadNotificationCount}) ${updatedTitle}`
                }
            } else {
                // Remove the notification count from the title if unreadNotificationCount is 0
                updatedTitle = updatedTitle.replace(regex, '')
            }

            document.title = updatedTitle
        }, 1000)

        return () => {
            clearTimeout(timeoutId)
        }
    }, [unreadNotificationCount])

    const setNotificationsRead = useCallback(() => {
        const notifs = notifications.map((notification) => ({
            ...notification,
            read: true
        }))
        setNotifications(notifs)
        setStoredNotifications(notifs)
        handleTitleUpdate()
    }, [notifications, handleTitleUpdate, setStoredNotifications])

    const setNotificationClicked = useCallback(
        (notification: Notification) => {
            const notifs = notifications.map((n) =>
                n === notification ? { ...n, clicked: true } : n
            )
            setNotifications(notifs)
            setStoredNotifications(notifs)
        },
        [notifications, setStoredNotifications]
    )

    useEffect(() => {
        handleTitleUpdate()
    }, [location, handleTitleUpdate])

    return {
        notifications,
        hasUnreadNotifications,
        refetchNotifications,
        setNotificationsRead,
        setNotificationClicked
    }
}

export default useGetPendingNudges
