import {
    DisplayTarget,
    NudgeType
} from '../../../graphql/generated/autogenerated'
import { ReactNullValue } from '../../../utils/nulls'

export enum ExtendedNudgeType {
    BrowserNotificationPermissionType = 'BROWSER_NOTIFICATION_PERMISSIONS',
    NewAchievementCountType = 'NEW_ACHIEVEMENT_COUNT'
}
export const BrowserNotificationPermissionType =
    'BROWSER_NOTIFICATION_PERMISSIONS' as NudgeType
export const PseudoBrowserPermissionNotification = {
    read: false,
    expired: false,
    clicked: false,
    expiresAt: ReactNullValue,
    nudgeMetadata: {},
    nudgeType: BrowserNotificationPermissionType,
    producedAt: ReactNullValue,
    sendDate: ReactNullValue,
    sendTime: '',
    sentAt: ReactNullValue,
    targetTimestamp: ReactNullValue,
    thriveUserId: ReactNullValue,
    whereToShow: ['WEB'] as DisplayTarget[]
}
export const NewAchievementCountType = 'NEW_ACHIEVEMENT_COUNT' as NudgeType
