import { Card, Divider, FormControlLabel, Stack, Switch } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { Avo } from '@thriveglobal/thrive-web-tracking'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import Image from '../../../../components/elements/Image/Image'
import useGetPlatform, {
    PlatformData
} from '../../../../functions/GetPlatformData/GetPlatformData'
import {
    NudgeType,
    useSetNudgeStatusMutation
} from '../../../../graphql/generated/autogenerated'
import useGetNudgeStatus from '../../../../hooks/useGetNudgeStatus/useGetNudgeStatus'
import {
    defaultNotificationSubscribedProps,
    defaultNotificationUnsubscribedProps
} from '../../../../utils/defaultTrackingProps'
import { ReactNullValue } from '../../../../utils/nulls'
import { Platform } from '../../../enums'

const messages = defineMessages({
    documentTitle: {
        defaultMessage: 'Notifications Hub',
        description: `Notifications Hub page title`
    },
    on: {
        defaultMessage: 'On',
        description: 'checkbox label on'
    },
    off: {
        defaultMessage: 'Off',
        description: 'checkbox label off'
    }
})

export interface SmartNudgesProps {
    platform?: Platform
    autoMutate?: boolean
    hideCard?: boolean
    onToggle?: (enabled: boolean) => void
    onLoaded?: (enabled: boolean) => void
    onError?: () => void
}

const SmartNudges: React.FC<SmartNudgesProps> = ({
    platform,
    autoMutate = true,
    hideCard,
    onToggle,
    onLoaded,
    onError
}) => {
    const { formatMessage } = useIntl()
    const [resetSmartNudgeEnabled, setResetSmartNudgeEnabled] =
        useState<boolean>(false)

    const {
        statuses,
        error: nudgeStatusError,
        loading: nudgeStatusLoading
    } = useGetNudgeStatus()
    const [setNudgeStatusMutation] = useSetNudgeStatusMutation()

    useEffect(() => {
        if (nudgeStatusError) onError?.()
    }, [nudgeStatusError, onError])

    useEffect(() => {
        if (!nudgeStatusLoading) {
            const enabled = statuses[NudgeType.SmartNudgeMsTeamsMvp]
            setResetSmartNudgeEnabled(enabled)
            onLoaded?.(enabled)
        }
    }, [statuses, onLoaded, nudgeStatusLoading])

    const handleOnChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const isEnabled = event.target.checked
            setResetSmartNudgeEnabled(isEnabled)
            if (autoMutate) {
                setNudgeStatusMutation({
                    variables: {
                        input: {
                            enabled: isEnabled,
                            type: NudgeType.SmartNudgeMsTeamsMvp
                        }
                    }
                })
                isEnabled
                    ? Avo.notificationSubscribed({
                          ...defaultNotificationSubscribedProps,
                          featureType: 'notifications',
                          activityType: 'reset_notification_subscribed',
                          nudgeType: NudgeType.SmartNudgeMsTeamsMvp
                      })
                    : Avo.notificationUnsubscribed({
                          ...defaultNotificationUnsubscribedProps,
                          featureType: 'notifications',
                          activityType: 'reset_notification_unsubscribed',
                          nudgeType: NudgeType.SmartNudgeMsTeamsMvp
                      })
            } else {
                onToggle?.(isEnabled)
            }
        },
        [setNudgeStatusMutation, autoMutate, onToggle]
    )

    const smartNudgeSwitch = useMemo(
        () => (
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
            >
                <Stack gap={1}>
                    <CoreTypography variant="h5">
                        <FormattedMessage
                            defaultMessage="Resets"
                            description="resets option title"
                        />
                    </CoreTypography>
                    <CoreTypography variant="body1">
                        <FormattedMessage
                            defaultMessage="Get a 60-second Reset through MS Teams each day."
                            description="resets description"
                        />
                    </CoreTypography>
                </Stack>
                <FormControlLabel
                    control={
                        <Switch
                            checked={resetSmartNudgeEnabled}
                            onChange={handleOnChange}
                            inputProps={{
                                'aria-label': 'controlled'
                            }}
                        />
                    }
                    label={
                        resetSmartNudgeEnabled
                            ? formatMessage(messages.on)
                            : formatMessage(messages.off)
                    }
                />
            </Stack>
        ),
        [resetSmartNudgeEnabled, handleOnChange, formatMessage]
    )

    const platformData: PlatformData | undefined = useGetPlatform(platform)
    if (!platformData && !hideCard) return ReactNullValue

    return hideCard ? (
        smartNudgeSwitch
    ) : (
        <Stack data-testid="smart-nudges">
            <Card variant="outlined">
                <Stack p={3}>
                    <Stack gap={2}>
                        <Stack direction="row" alignItems="center" gap={1}>
                            <Image
                                src={String(platformData?.imgUrl)}
                                height={36}
                                width={36}
                            />
                            <CoreTypography variant="h5">
                                {platformData?.title}
                            </CoreTypography>
                        </Stack>
                        <Divider />
                        {smartNudgeSwitch}
                    </Stack>
                </Stack>
            </Card>
        </Stack>
    )
}

export default SmartNudges
