import { Stack } from '@mui/material'
import {
    CoreTypography,
    IconButton,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import { FormattedMessage } from 'react-intl'

const NotificationsEmpty: React.FC = () => {
    const { palette } = useTheme()
    return (
        <Stack
            pb={2}
            gap={1}
            alignItems="center"
            justifyContent="center"
            data-testid="notifications-empty"
        >
            <IconButton
                variant="contained"
                color="secondary"
                sx={{ pointerEvents: 'none' }}
            >
                <LeafIcon icon="party-horn" fontSize="small" />
            </IconButton>
            <CoreTypography>
                <FormattedMessage
                    defaultMessage="You're all caught up!"
                    description="caught up description"
                />
            </CoreTypography>
        </Stack>
    )
}

export default NotificationsEmpty
