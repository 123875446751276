import {
    NotificationType,
    setNotification,
    useAppSelector
} from '@thriveglobal/thrive-web-core'
import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

export const useShowPlantNotification = () => {
    const { activePlants, isLoading } = useAppSelector((state) => state.garden)
    const dispatch = useDispatch()
    const [showNotification, setShowNotification] = useState(false)
    const prevStepsRef = useRef(activePlants?.[0]?.progressSteps)

    useEffect(() => {
        const newSteps = activePlants?.[0]?.progressSteps
        if (!isLoading && newSteps > prevStepsRef.current) {
            dispatch(
                setNotification({
                    type: NotificationType.PLANT_WATERED,
                    payload: {},
                    autohide: false,
                    color: 'info'
                })
            )
            setShowNotification(true)
        }
        prevStepsRef.current = newSteps
    }, [activePlants, isLoading, dispatch])

    return { showNotification, setShowNotification }
}

export default useShowPlantNotification
