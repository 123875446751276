import { defineMessages, useIntl } from 'react-intl'
import { NudgeType } from '../../graphql/generated/autogenerated'
import { Platform } from '../../shared'

export const imgUrls = {
    Web: 'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/c6669e56-26fe-4317-137a-93dadee45600/cms',
    Mobile: 'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/376117b2-6de6-441d-d1b1-66c86168e000/cms',
    Slack: 'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/c216bfe4-d02c-4d87-c0e8-385afa862700/cms',
    Teams: 'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/3a6f1eaf-b3da-497c-5a5b-fa00cba75700/cms',
    Webex: 'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/33fcb24c-5cfd-4439-7b4c-7b0bfb683600/cms'
}

const messages = defineMessages({
    Web: {
        defaultMessage: 'Thrive for Web',
        description: 'platform name'
    },
    Slack: {
        defaultMessage: 'Slack',
        description: 'platform name'
    },
    HumanAPI: {
        defaultMessage: 'HumanAPI',
        description: 'platform name'
    },
    Mobile: {
        defaultMessage: 'Thrive Mobile App',
        description: 'platform name'
    },
    Teams: {
        defaultMessage: 'MS Teams',
        description: 'platform name'
    },
    Webex: {
        defaultMessage: 'Thrive for Webex',
        description: 'platform name'
    }
})

export interface PlatformData {
    title: string
    imgUrl: string
    nudgeType: NudgeType | undefined
}

export const GetPlatformData = (
    platform?: Platform
): PlatformData | undefined => {
    const { formatMessage } = useIntl()
    let platformData: PlatformData | undefined = undefined
    switch (platform) {
        case Platform.WEB:
            platformData = {
                title: formatMessage(messages.Web),
                imgUrl: imgUrls.Web,
                nudgeType: undefined
            }
            break
        case Platform.MOBILE:
            platformData = {
                title: formatMessage(messages.Mobile),
                imgUrl: imgUrls.Mobile,
                nudgeType: undefined
            }
            break
        case Platform.SLACK:
            platformData = {
                title: formatMessage(messages.Slack),
                imgUrl: imgUrls.Slack,
                nudgeType: undefined
            }
            break
        case Platform.TEAMS:
            platformData = {
                title: formatMessage(messages.Teams),
                imgUrl: imgUrls.Teams,
                nudgeType: NudgeType.SmartNudgeMsTeamsMvp
            }
            break
        case Platform.WEBEX:
            platformData = {
                title: formatMessage(messages.Webex),
                imgUrl: imgUrls.Webex,
                nudgeType: undefined
            }
            break
    }

    return platformData
}

export default GetPlatformData
