import {
    FlagKey,
    useFlagStore as useAchievementFlagStore
} from '@thriveglobal/thrive-web-achievements'
import {
    fetchAndStoreAchievements,
    fetchAndStoreGarden,
    fetchAndStoreWallet,
    useAppSelector,
    useCrossAppNavigation
} from '@thriveglobal/thrive-web-core'
import React, { useCallback, useEffect, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import useShowNotification from '../../../../hooks/useShowNotification/useShowNotification'
import useShowPlantNotification from '../../../../hooks/useShowPlantNotification/useShowPlantNotification'
import NotificationSnackBar from '../NotificationSnackBar/NotificationSnackBar'
import { generateNotification } from '../NotificationSnackBar/generate'

const messages = defineMessages({
    openAchievementCenter: {
        defaultMessage: 'Open achievement center popup',
        description:
            'description for the visually-impaired of the button action'
    },
    closeAriaLabel: {
        defaultMessage: 'close',
        description: 'description for the visually-impaired of the close button'
    }
})

const NotificationSnackBarController: React.FC = () => {
    const navigate = useCrossAppNavigation()
    const { formatMessage } = useIntl()

    const [activeNotification, setActiveNotification] = useState<any>(undefined)
    const [showNotification, setShowNotification] = useState<boolean>(false)
    const [showConfetti, setShowConfetti] = useState<boolean>(false)

    const { activePlants } = useAppSelector((state) => state.garden)
    const notificationState = useAppSelector((state) => state.notification)

    // Load achievements, wallet, and garden on mount
    // If there have been changes since last load, redux will dispatch new values
    useEffect(() => {
        fetchAndStoreAchievements()
        fetchAndStoreWallet()
        fetchAndStoreGarden()
    }, [])

    const {
        showNotification: showNewNotification,
        setShowNotification: setShowNewNotification
    } = useShowNotification()

    const {
        showNotification: showPlantNotification,
        setShowNotification: setShowPlantNotification
    } = useShowPlantNotification()

    const { setFlag, getFlag, loading } = useAchievementFlagStore()

    const handleCloseNotification = useCallback(() => {
        setShowNewNotification(false)
        setFlag(FlagKey.LAST_ACHIEVEMENT_BANNER, new Date().toISOString())
        setShowPlantNotification(false)
    }, [setFlag, setShowNewNotification, setShowPlantNotification])

    const handleRoute = useCallback(
        (route: string) => {
            handleCloseNotification()
            navigate(route)
        },
        [navigate, handleCloseNotification]
    )

    const generateAndSetNotification = useCallback(() => {
        setActiveNotification(
            generateNotification(
                notificationState.notification,
                formatMessage(messages.closeAriaLabel),
                formatMessage,
                handleRoute,
                handleCloseNotification
            )
        )
        setShowConfetti(true)
    }, [
        handleRoute,
        formatMessage,
        setShowConfetti,
        handleCloseNotification,
        notificationState.notification
    ])

    // Plant Watered Notification Effect
    useEffect(() => {
        if (
            activePlants?.length > 0 &&
            notificationState.notification &&
            showNotification !== showPlantNotification
        ) {
            setShowNotification(showPlantNotification)

            if (showPlantNotification) {
                generateAndSetNotification()
            }
        }
    }, [
        showNotification,
        activePlants?.length,
        showPlantNotification,
        generateAndSetNotification,
        notificationState.notification
    ])

    useEffect(() => {
        if (
            activePlants?.length === 0 &&
            showNotification !== showNewNotification
        ) {
            setShowNotification(showNewNotification)
            generateAndSetNotification()
        }
    }, [
        showNotification,
        showNewNotification,
        activePlants?.length,
        generateAndSetNotification
    ])

    return (
        <NotificationSnackBar
            {...activeNotification}
            open={showNotification}
            onNavigate={handleRoute}
            onClose={handleCloseNotification}
            onConfettiComplete={() => setShowConfetti(false)}
            confetti={showConfetti}
        />
    )
}

export default NotificationSnackBarController
