import { useCallback, useMemo } from 'react'
import { ReactNullValue } from '../../utils/nulls'

const useLocalStorage = <T>(key: string) => {
    const get = useCallback((): T | typeof ReactNullValue => {
        const storedValue = localStorage.getItem(key)
        return storedValue ? (JSON.parse(storedValue) as T) : ReactNullValue
    }, [key])

    const set = useCallback(
        (value: T) => {
            localStorage.setItem(key, JSON.stringify(value))
        },
        [key]
    )

    return useMemo(() => ({ get, set }), [get, set])
}

export default useLocalStorage
