import {
    FlagKey,
    useFlagStore as useAchievementFlagStore
} from '@thriveglobal/thrive-web-achievements'
import {
    NotificationType,
    setNotification,
    useAppSelector
} from '@thriveglobal/thrive-web-core'
import isAfter from 'date-fns/isAfter'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

export type NotificationData = {
    showNotification: boolean
    newNotificationCount: number
    setShowNotification: (show: boolean) => void
}

export const useShowNotification = () => {
    const dispatch = useDispatch()
    const { hasFlag, setFlag, getFlag, loading } = useAchievementFlagStore()
    const [showNotification, setShowNotification] = useState<boolean>(false)
    const { progress } = useAppSelector((state) => state.achievement)
    const [initializedDate] = useState<Date>(new Date())
    const hasShownNotification = useRef(false)

    const countNewNotifications = useCallback(
        (lastDate: Date) =>
            progress.reduce(
                (count, { levels }) =>
                    count +
                    levels.filter(
                        (level) =>
                            level.completedAt &&
                            isAfter(
                                new Date(level.completedAt),
                                new Date(lastDate)
                            ) &&
                            isAfter(
                                new Date(level.completedAt),
                                initializedDate
                            )
                    ).length,
                0
            ),
        [progress, initializedDate]
    )

    const { newNotificationCount } = useMemo(() => {
        let newNotificationCount = 0
        if (loading) return { newNotificationCount }
        const lastAchievementBanner = hasFlag(FlagKey.LAST_ACHIEVEMENT_BANNER)
            ? new Date(getFlag(FlagKey.LAST_ACHIEVEMENT_BANNER) as string)
            : undefined
        if (lastAchievementBanner) {
            newNotificationCount = countNewNotifications(lastAchievementBanner)
        }
        return { newNotificationCount }
    }, [loading, hasFlag, getFlag, countNewNotifications])

    useEffect(() => {
        if (!loading && !hasFlag(FlagKey.LAST_ACHIEVEMENT_BANNER)) {
            setFlag(FlagKey.LAST_ACHIEVEMENT_BANNER, new Date().toISOString())
        }
    }, [hasFlag, setFlag, loading])

    useEffect(() => {
        if (newNotificationCount > 0 && !hasShownNotification.current) {
            hasShownNotification.current = true
            setShowNotification(false)
            dispatch(
                setNotification({
                    type: NotificationType.ACHIEVEMENT,
                    payload: { counter: newNotificationCount },
                    autohide: false,
                    color: 'success'
                })
            )
            setShowNotification(true)
        }
    }, [newNotificationCount, dispatch, setFlag])

    return useMemo<NotificationData>(
        () => ({
            showNotification,
            newNotificationCount,
            setShowNotification
        }),
        [showNotification, newNotificationCount]
    )
}

export default useShowNotification
