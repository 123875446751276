import { Badge, Box } from '@mui/material'
import { useIsFeatureEnabled } from '@thriveglobal/thrive-web-core'
import { ErrorBoundary } from '@thriveglobal/thrive-web-errors'
import {
    IconButton,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import { Avo } from '@thriveglobal/thrive-web-tracking'
import React, { useCallback, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useBrowserNotification } from '../../../../hooks/useBrowserNotification/useBrowserNotification'
import useGetPendingNudges from '../../../../hooks/useGetPendingNudges/useGetPendingNudges'
import { defaultPromptSelectedProps } from '../../../../utils/defaultTrackingProps'
import { ReactNullValue } from '../../../../utils/nulls'
import NotificationMenu from '../NotificationMenu/NotificationMenu'
import PermissionSnackBar from '../PermissionSnackBar/PermissionSnackBar'
import { Notification } from './../../../../hooks/useGenerateNotification/useGenerateNotification'

const messages = defineMessages({
    notifications: {
        defaultMessage: 'Notifications',
        description: `Notifications menu button`
    }
})

const NotificationMenuButton = () => {
    const {
        notifications,
        hasUnreadNotifications,
        setNotificationsRead,
        setNotificationClicked
    } = useGetPendingNudges()
    const { formatMessage } = useIntl()
    const { palette } = useTheme()
    const [menuOpen, setMenuOpen] = useState<boolean>(false)

    const { canUseBrowserNotifications } = useBrowserNotification()

    const browserNotificationsEnabled = useIsFeatureEnabled(
        'BrowserNotifications',
        ReactNullValue,
        true
    )

    const [hasPermission, setHasPermission] = useState<boolean>(false)
    const [isPermissionSnackBarOpen, setIsPermissionSnackBarOpen] =
        useState<boolean>(false)

    const onClickHandler = useCallback(async () => {
        setMenuOpen(true)
        Avo.promptSelected({
            ...defaultPromptSelectedProps,
            featureType: 'notifications'
        })
    }, [])

    const onHandleClose = useCallback(() => {
        setNotificationsRead()
        setMenuOpen(false)
    }, [setNotificationsRead])

    const onSetClicked = useCallback(
        (notification: Notification) => {
            setNotificationClicked(notification)
        },
        [setNotificationClicked]
    )

    return (
        <React.Fragment>
            <Box data-testid="notification-menu-button">
                <IconButton
                    tabIndex={0}
                    color="primary"
                    data-testid="bell-icon"
                    aria-label={formatMessage(messages.notifications)}
                    onClick={onClickHandler}
                >
                    <Badge
                        badgeContent={hasUnreadNotifications ? 1 : 0}
                        color="secondary"
                        variant="dot"
                        overlap="circular"
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                        sx={{
                            '& .MuiBadge-dot': {
                                top: -4,
                                width: 12,
                                right: -1,
                                height: 12,
                                borderRadius: '50%',
                                backgroundColor: palette.journeys.connect.main
                            }
                        }}
                    >
                        <LeafIcon icon="bell" />
                    </Badge>
                </IconButton>
            </Box>
            {canUseBrowserNotifications && browserNotificationsEnabled && (
                <PermissionSnackBar
                    isOpen={isPermissionSnackBarOpen}
                    hasPermission={hasPermission}
                    onHandleClose={() => setIsPermissionSnackBarOpen(false)}
                />
            )}
            <NotificationMenu
                isOpen={menuOpen}
                notifications={notifications}
                onHandleClose={onHandleClose}
                onSetClicked={onSetClicked}
                onSetOpenPermissionSnackbar={(permission: boolean) => {
                    setHasPermission(permission)
                    setIsPermissionSnackBarOpen(true)
                }}
            />
        </React.Fragment>
    )
}

export const WrappedNotificationMenuButton = () => {
    return (
        <ErrorBoundary
            boundaryName="NotificationMenuButton"
            boundaryType="Component"
            packageName={process.env.APP_NAME as string}
            packageOwner={process.env.PROJECT_OWNER as string}
            packageVersion={process.env.APP_VERSION as string}
        >
            <NotificationMenuButton />
        </ErrorBoundary>
    )
}

export default WrappedNotificationMenuButton
