import { AlertTitle, Snackbar, useMediaQuery } from '@mui/material'
import {
    CoreAlert,
    CoreTypography,
    IconButton,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import { defineMessages, useIntl } from 'react-intl'

const messages = defineMessages({
    success: {
        defaultMessage: 'Success!',
        description: `Permission Snackbar title`
    },
    noProblem: {
        defaultMessage: 'No problem!',
        description: `Permission Snackbar title`
    },
    successMessage: {
        defaultMessage:
            'You will start receiving browser notifications from Thrive.',
        description: `Permission Snackbar message`
    },
    noProblemMessage: {
        defaultMessage: `You can always update your preferences in your profile.`,
        description: `Permission Snackbar message`
    }
})

export type PermissionSnackBarProps = {
    isOpen: boolean
    hasPermission?: boolean
    onHandleClose: () => void
}

const PermissionSnackBar: React.FC<PermissionSnackBarProps> = ({
    isOpen,
    hasPermission,
    onHandleClose
}) => {
    const { breakpoints } = useTheme()
    const { formatMessage } = useIntl()
    const isMobile = useMediaQuery(breakpoints.down('sm'))
    const snackbarSx = { top: isMobile ? '74px!important' : '100px!important' }

    return (
        <Snackbar
            open={isOpen}
            autoHideDuration={6000}
            onClose={onHandleClose}
            message="Note archived"
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            sx={snackbarSx}
        >
            <CoreAlert
                elevation={8}
                severity={hasPermission ? 'success' : 'info'}
                variant="standard"
                action={
                    <IconButton
                        data-testid="plant-alert-close"
                        color="primary"
                        onClick={onHandleClose}
                    >
                        <LeafIcon icon="close" />
                    </IconButton>
                }
            >
                <AlertTitle>
                    <CoreTypography variant="h5" component="h2">
                        {hasPermission
                            ? formatMessage(messages.success)
                            : formatMessage(messages.noProblem)}
                    </CoreTypography>
                </AlertTitle>
                <CoreTypography variant="body1">
                    {hasPermission
                        ? formatMessage(messages.successMessage)
                        : formatMessage(messages.noProblemMessage)}
                </CoreTypography>
            </CoreAlert>
        </Snackbar>
    )
}

export default PermissionSnackBar
