import {
    AlertTitle,
    Box,
    Card,
    Snackbar,
    SnackbarCloseReason,
    Stack,
    useMediaQuery
} from '@mui/material'
import { NotificationType } from '@thriveglobal/thrive-web-core'
import {
    ConfettiExplosion,
    CoreAlert,
    CoreTypography,
    IconButton,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, {
    ReactFragment,
    SyntheticEvent,
    useCallback,
    useEffect,
    useState
} from 'react'
import Image from '../../../../../components/elements/Image/Image'

const plantAlertImageSrc =
    'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/7dbe14d1-c5a5-4459-3400-7b6b09db8100/cms'

export type NotificationSnackBarProps = {
    open: boolean
    title: ReactFragment
    subtitle: ReactFragment
    action: ReactFragment
    type: NotificationType
    severity: 'success' | 'info' | 'warning' | 'error'
    color: 'success' | 'info' | 'warning' | 'error'
    confetti: boolean
    autohide: boolean
    onConfettiComplete: () => void
    onClose: () => void
}

const NotificationSnackBar: React.FC<NotificationSnackBarProps> = ({
    open,
    title,
    subtitle,
    action,
    type,
    severity,
    color,
    confetti,
    autohide,
    onConfettiComplete,
    onClose
}) => {
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('sm'))
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(open)
    const [showConfetti, setShowConfetti] = useState<boolean>(confetti)
    useEffect(() => {
        setSnackbarOpen(open)
    }, [open])
    useEffect(() => {
        setShowConfetti(confetti)
    }, [confetti])

    const closeHandler = useCallback(
        (
            _: Event | SyntheticEvent<any, Event>,
            reason: SnackbarCloseReason
        ) => {
            if (!autohide && reason === 'clickaway') return
            setSnackbarOpen(false)
            onClose()
        },
        [autohide, onClose]
    )

    const confettiHandler = useCallback(() => {
        if (showConfetti === true) {
            setShowConfetti(false)
            onConfettiComplete()
        }
    }, [showConfetti, onConfettiComplete])

    return (
        <Snackbar
            data-testid="notification"
            open={snackbarOpen}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            onClose={closeHandler}
            {...(!!autohide && { autoHideDuration: 6000 })}
        >
            <Stack>
                {type === NotificationType.PLANT_WATERED && (
                    <Card>
                        <Stack
                            direction="row"
                            width={!isMobile ? 617 : undefined}
                            height={98}
                            gap={1.25}
                        >
                            <Image
                                src={plantAlertImageSrc}
                                height="100%"
                                width="30%"
                                cover
                                backgroundPositionX="right"
                            />
                            <Stack
                                flex={1}
                                direction="row"
                                alignItems="flex-start"
                                p={1.5}
                            >
                                <Stack
                                    flex={1}
                                    gap={0.5}
                                    height="100%"
                                    alignItems="flex-start"
                                    justifyContent="center"
                                >
                                    <CoreTypography variant="h5">
                                        {title}
                                    </CoreTypography>
                                    {subtitle}
                                </Stack>
                                <IconButton
                                    color="primary"
                                    onClick={closeHandler}
                                    data-testid="plant-alert-close"
                                >
                                    <LeafIcon icon="close" />
                                </IconButton>
                            </Stack>
                        </Stack>
                    </Card>
                )}
                {type !== NotificationType.PLANT_WATERED && (
                    <Stack position="relative">
                        <CoreAlert
                            elevation={8}
                            severity={severity}
                            variant="standard"
                            color={color}
                            action={action}
                        >
                            <AlertTitle>{title}</AlertTitle>
                            {subtitle}
                        </CoreAlert>
                        <Box position="absolute" top={0} right={0}>
                            <ConfettiExplosion
                                show={showConfetti}
                                onComplete={confettiHandler}
                            />
                        </Box>
                    </Stack>
                )}
            </Stack>
        </Snackbar>
    )
}

export default NotificationSnackBar
