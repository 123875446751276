import { CircularProgress, ThemeProvider } from '@mui/material'
import { ProtectedRoute } from '@thriveglobal/thrive-web-auth-core'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { Suspense, lazy } from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'

const NotificationFeed = lazy(
    () => import('../pages/NotificationFeed/NotificationFeed')
)

const NotificationSettings = lazy(
    () => import('../pages/NotificationSettings/NotificationSettings')
)

export enum ROUTES {
    NOTIFICATIONS = '/notifications',
    NOTIFICATION_SETTINGS = '/notifications/settings'
}

const Router: React.FC = () => {
    const theme = useTheme()
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Suspense fallback={<CircularProgress />}>
                    <Switch>
                        <ProtectedRoute
                            path={ROUTES.NOTIFICATION_SETTINGS}
                            component={NotificationSettings}
                        />
                        <ProtectedRoute
                            path={ROUTES.NOTIFICATIONS}
                            component={NotificationFeed}
                        />
                    </Switch>
                </Suspense>
            </BrowserRouter>
        </ThemeProvider>
    )
}

export default Router
