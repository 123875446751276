import { ApolloError } from '@apollo/client'
import { Apollo } from '@thriveglobal/thrive-web-core'
import { useMemo } from 'react'
import {
    GetNudgeStatusQuery,
    NudgeType,
    UserNudgeStatusResult,
    useGetNudgeStatusQuery
} from '../../graphql/generated/autogenerated'

export const NudgeStatusSuccess = 'UserNudgeStatusFetchSuccess'
export const NudgeStatusFailure = 'UserNudgeStatusFetchFailure'

export type NudgeStatus =
    | {
          __typename?: typeof NudgeStatusFailure
          error: string
      }
    | {
          __typename?: typeof NudgeStatusSuccess
          result: {
              __typename?: 'UserNudgeStatusResult'
              thriveUserId: any
              nudgeType: NudgeType
              enabled: boolean
              updatedAt: any
          }[]
      }
    | undefined

export interface NudgeStatuses {
    [NudgeType.SmartNudgeMsTeamsMvp]: boolean
}

export interface NudgeStatusData {
    statuses: NudgeStatuses
    loading: boolean
    error: ApolloError | undefined
    refetch: () => Promise<Apollo.ApolloQueryResult<GetNudgeStatusQuery>>
}

export const useGetNudgeStatus = () => {
    const { data, loading, error, refetch } = useGetNudgeStatusQuery()
    const statuses = useMemo(() => {
        const nudgeStatuses: NudgeStatus =
            data?.centralizedNotifications?.nudgeStatus
        return {
            [NudgeType.SmartNudgeMsTeamsMvp]:
                nudgeStatuses?.__typename === NudgeStatusSuccess &&
                nudgeStatuses.result.some(
                    (r: UserNudgeStatusResult) =>
                        r.nudgeType === NudgeType.SmartNudgeMsTeamsMvp &&
                        r.enabled
                )
        }
    }, [data])

    return useMemo<NudgeStatusData>(
        () => ({
            statuses,
            loading,
            error,
            refetch
        }),
        [statuses, loading, error, refetch]
    )
}

export default useGetNudgeStatus
