import { Link } from '@mui/material'
import {
    NotificationModel,
    NotificationType
} from '@thriveglobal/thrive-web-core'
import {
    CoreTypography,
    IconButton,
    LeafIcon
} from '@thriveglobal/thrive-web-leafkit'
import { MessageDescriptor, defineMessages } from 'react-intl'

const messages = defineMessages({
    unlockedAchievements: {
        defaultMessage: `You've unlocked {counter, plural, =0 {No achievements} one {# achievement.} other {# achievements.}}`,
        description: 'unlocked achievements message'
    },
    claimAchievements: {
        defaultMessage: `{counter, plural, =0 {} one {<a>Claim it now!</a>} other {<a>Claim them now!</a>}}`,
        description: 'claim achievements cta'
    },
    earnedAchievements: {
        defaultMessage: `You've earned {counter, plural, =0 {No achievements} one {# achievement!} other {# achievements!}}`,
        description: 'earned achievements message'
    },
    viewAchievements: {
        defaultMessage: `<a>View Achievements</a>`,
        description: 'view achievements cta'
    },
    wayToGo: {
        defaultMessage: 'Way to go!',
        description: 'plant watered title'
    },
    makingProgress: {
        defaultMessage: 'By making progress, you just watered your plant.',
        description: 'plant watered message'
    }
})

export const generateNotification = (
    notification: NotificationModel,
    closeAriaLabel: string,
    formatMessage: (
        descriptor: MessageDescriptor,
        values?: any,
        opts?: any
    ) => string,
    onNavigate: (route: string) => void,
    onClose: () => void
) => {
    switch (notification.type) {
        case NotificationType.ERROR: {
            return {
                title: (
                    <CoreTypography variant="h5" component="h2">
                        {notification.payload.title}
                    </CoreTypography>
                ),
                subtitle: (
                    <CoreTypography variant="body1">
                        {notification.payload.message}
                    </CoreTypography>
                ),
                action: (
                    <IconButton
                        sx={{ ml: 3 }}
                        onClick={onClose}
                        aria-label={closeAriaLabel}
                    >
                        <LeafIcon icon={'xmark'} />
                    </IconButton>
                ),
                severity: notification.color,
                color: notification.color,
                autohide: notification.autohide,
                type: NotificationType.ERROR
            }
        }
        case NotificationType.ACHIEVEMENT: {
            return {
                title: (
                    <CoreTypography variant="h5" component="h2">
                        {formatMessage(messages.earnedAchievements, {
                            counter: notification.payload.counter
                        })}
                    </CoreTypography>
                ),
                subtitle: (
                    <CoreTypography variant="body1">
                        {formatMessage(messages.viewAchievements, {
                            counter: notification.payload.counter,
                            a: (chunks: string) => (
                                <Link
                                    component={'button'}
                                    variant="body1"
                                    color="inherit"
                                    onClick={() => onNavigate('/achievements')}
                                >
                                    {chunks}
                                </Link>
                            )
                        })}
                    </CoreTypography>
                ),
                action: (
                    <IconButton
                        sx={{ ml: 3 }}
                        onClick={onClose}
                        aria-label={closeAriaLabel}
                    >
                        <LeafIcon icon={'xmark'} />
                    </IconButton>
                ),
                severity: notification.color,
                color: notification.color,
                autohide: notification.autohide,
                type: NotificationType.ACHIEVEMENT
            }
        }
        case NotificationType.PLANT_WATERED: {
            return {
                title: (
                    <CoreTypography variant="h5" component="h2">
                        {formatMessage(messages.wayToGo)}
                    </CoreTypography>
                ),
                subtitle: (
                    <CoreTypography variant="body1">
                        {formatMessage(messages.makingProgress)}
                    </CoreTypography>
                ),
                action: (
                    <IconButton
                        sx={{ ml: 3 }}
                        onClick={onClose}
                        aria-label={closeAriaLabel}
                    >
                        <LeafIcon icon={'xmark'} />
                    </IconButton>
                ),
                severity: notification.color,
                color: notification.color,
                autohide: notification.autohide,
                type: NotificationType.PLANT_WATERED
            }
        }
    }
}
