import { IconName } from '@fortawesome/fontawesome-common-types'
import { Box, Button, Stack } from '@mui/material'
import { useCrossAppNavigation } from '@thriveglobal/thrive-web-core'
import {
    CoreTypography,
    IconButton,
    LeafIcon
} from '@thriveglobal/thrive-web-leafkit'
import React, { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'
import {
    BrowserNotificationPermission,
    useBrowserNotification
} from '../../../hooks/useBrowserNotification/useBrowserNotification'
import useGenerateNotification, {
    Notification,
    WebNotification
} from './../../../hooks/useGenerateNotification/useGenerateNotification'

// We are using this override to match designs until we get a small version of the buttons.
export const buttonSxOverride = {
    maxWidth: '32px!important',
    minHeight: '32px!important',
    minWidth: '32px!important'
}

export interface NotificationItemProps {
    notification: Notification
    webNotification: WebNotification
    handleClose: (notification?: Notification) => void
    setClicked: (notification: Notification) => void
    setOpenPermissionSnackbar?: (allowed: boolean) => void
}

const NotificationItem: React.FC<NotificationItemProps> = ({
    notification,
    webNotification,
    handleClose,
    setClicked,
    setOpenPermissionSnackbar
}) => {
    const history = useHistory()
    const navigation = useCrossAppNavigation()
    const { requestPermission } = useBrowserNotification()
    const { logResourceSelected, logResourceDeferred } =
        useGenerateNotification()

    const dismissPermissionNotification = useCallback(
        (permission: boolean) => {
            setClicked(notification)
            setOpenPermissionSnackbar?.(permission)
        },
        [notification, setClicked, setOpenPermissionSnackbar]
    )

    const openPermission = useCallback(async () => {
        const permission = await requestPermission()
        switch (permission) {
            case BrowserNotificationPermission.DENIED:
                handleClose()
                dismissPermissionNotification(false)
                break
            case BrowserNotificationPermission.GRANTED:
                handleClose()
                dismissPermissionNotification(true)
                break
            default:
                break
        }
    }, [dismissPermissionNotification, handleClose, requestPermission])

    const onCtaClick = useCallback(() => {
        logResourceSelected(notification)

        if (webNotification?.notificationCtaRoute) {
            navigation(webNotification?.notificationCtaRoute)
            handleClose()
            const timeout = setTimeout(() => {
                setClicked(notification)
            }, 1000)
            return () => clearTimeout(timeout)
        } else {
            openPermission()
        }
    }, [
        navigation,
        setClicked,
        handleClose,
        notification,
        openPermission,
        webNotification,
        logResourceSelected
    ])

    const onDismissClickHandler = useCallback(() => {
        logResourceDeferred(notification)
        setClicked(notification)
    }, [notification, logResourceDeferred, setClicked])

    return (
        <Stack
            gap={1.25}
            direction="row"
            alignItems="start"
            data-testid="notification-item"
        >
            <IconButton
                variant="contained"
                color="secondary"
                sx={{
                    pointerEvents: 'none',
                    ...buttonSxOverride
                }}
            >
                <LeafIcon
                    icon={webNotification?.notificationIcon as IconName}
                    fontSize="small"
                />
            </IconButton>
            <Stack flex={1} gap={1}>
                {webNotification?.notificationMessage}
                <Stack direction="row" alignItems="center" gap={2}>
                    <Box>
                        <Button
                            variant="contained"
                            size="small"
                            color="secondary"
                            onClick={onCtaClick}
                            data-testid="notification-item-cta-button"
                        >
                            <CoreTypography>
                                {webNotification?.notificationButtonTitle}
                            </CoreTypography>
                        </Button>
                    </Box>
                    {webNotification?.notificationCanDismiss && (
                        <Box>
                            <Button
                                size="small"
                                onClick={onDismissClickHandler}
                                data-testid="notification-item-dismiss-button"
                            >
                                <CoreTypography>
                                    <FormattedMessage
                                        defaultMessage="Not now"
                                        description="not now button"
                                    />
                                </CoreTypography>
                            </Button>
                        </Box>
                    )}
                </Stack>
            </Stack>
        </Stack>
    )
}

export default NotificationItem
